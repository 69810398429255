
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { CollectionResourceDoc } from '@/models/jsonapi'
  import { Ticket } from "@/models/tickets";
  import { BvTableCtxObject } from "bootstrap-vue"
  import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"
  import { lastTicketActivity } from "@/utils/dataExtractors"

  export default defineComponent({
    setup(_) {
      const root = getCurrentInstance().proxy
      const totalItems = computed(() => root.$store.state.tickets.pagination.totalItems)

      const getItems = async(ctx: BvTableCtxObject) => {
        const queryParams = {
          'filter[archived]': false,
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'sort': '-createdAt',
        }
        const response = await root.$store.dispatch('tickets/load', queryParams)
        return response.data
      }

      const columns = ref([
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "ticketableType",
          title: "Typ",
          sortable: true,
          options: {
            contentFunction: (data:any) => {
              return data.item.attributes.ticketableType
            },
            hrefFunction: (data: any) => {
              const poly = data.item.relationships.ticketable.data
              return `/${poly.type}/${poly.id}`
            },
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.category",
          title: "Kategorie",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "attributes.subject",
          title: "Betreff",
          sortable: true,
          options: {
            hrefFunction: (data: any) => `/${data.item.type}/${data.item.id}`,
            contentFunction: (data: any) => {
              const item = data.item.attributes
              return item.subject || 'Kein Betreff'
            }
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.archived",
          title: "Archiviert",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "messageCount",
          title: "Nachrichten",
          sortable: true,
          options: {
            contentFunction: (data: any) => data.item.attributes.messages.length
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "lastMessage",
          title: "Letzte Änderung",
          sortable: true,
          options: {
            type: 'datetime',
            contentFunction: (data: any) => lastTicketActivity(data.item)
          },
        },
      ])

      return {
        getItems,
        totalItems,
        columns
      }
    }
  })
